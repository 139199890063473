import pick from 'lodash/pick';
import config from '../../config';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import {
  initiatePrivileged,
  sendEditTransactionById,
  apiGetOrders,
  transitionPrivileged,
  recalculateLineItems,
  getShippingQuotation,
  addUsersShoppingCart,
  deleteUsersShoppingCart,
  createEmptytoken,
  getPaymentMethod,
  updateUser,
  updateTransactionMetadata,
  approvePaypalPaymentApi,
  createPaypalOrderApi,
  validateReferralCode,
  validateEnquiryTransaction
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import {
  isPrivileged,
  TRANSITION_ENQUIRY_SEND_OFFER,
  TRANSITION_ENQUIRY_REQUEST_PAYMENT,
  TRANSITION_SEND_OFFER,
  TRANSITION_CONFIRM_PAYMENT_QUICK_PURCHASE,
  TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE,
  TRANSITION_INITIATE_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT
} from '../../util/transaction';

import { TRANSITION_ENQUIRE } from '../../util/transaction';

import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const formatLineItems = lineItems => {
  return {
    ...lineItems,
    lineTotal: !!lineItems.lineTotal ? new Money(lineItems.lineTotal.amount, 'MXN') : null,
    unitPrice: !!lineItems.unitPrice ? new Money(lineItems.lineTotal.amount, 'MXN') : null,
    quantity: !!lineItems.quantity ? new Decimal(lineItems.quantity) : null,
    percentage: !!lineItems.percentage ? new Decimal(lineItems.percentage) : null,
  }
}

const formatTotal = payTotal => new Money(payTotal.amount, 'MXN');

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const ADD_SHOPPING_CART_REQUEST = 'app/CheckoutPage/ADD_SHOPPING_CART_REQUEST';
export const ADD_SHOPPING_CART_SUCCESS = 'app/CheckoutPage/ADD_SHOPPING_CART_SUCCESS';

export const DELETE_SHOPPING_CART_REQUEST = 'app/CheckoutPage/DELETE_SHOPPING_CART_REQUEST';
export const DELETE_SHOPPING_CART_SUCCESS = 'app/CheckoutPage/DELETE_SHOPPING_CART_SUCCESS';

// To get the orders the buyer has done //

export const QUERY_TRANSACTION_REQUEST = 'app/ProfilePage/QUERY_TRANSACTION_REQUEST';
export const QUERY_TRANSACTION_SUCCESS = 'app/ProfilePage/QUERY_TRANSACTION_SUCCESS';
export const QUERY_TRANSACTION_ERROR = 'app/ProfilePage/QUERY_TRANSACTION_ERROR';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';
export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const UPDATE_REQUEST = 'app/CheckoutPage/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'app/CheckoutPage/UPDATE_SUCCESS';
export const UPDATE_ERROR = 'app/CheckoutPage/UPDATE_ERROR';

export const VALIDATION_REQUEST = 'app/CheckoutPage/VALIDATION_REQUEST';
export const VALIDATION_SUCCESS = 'app/CheckoutPage/VALIDATION_SUCCESS';
export const VALIDATION_ERROR = 'app/CheckoutPage/VALIDATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  updateInProgress: null,
  updateError: null,
  validationInProgress: null,
  validationError: null,
  userReferralCode: null,

};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_TRANSACTION_SUCCESS:
      return { ...state, sales: payload };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      return { ...state, stripeCustomerFetchError: payload };

    case SEND_ENQUIRY_REQUEST:
    return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case UPDATE_REQUEST:
      return { ...state, updateInProgress: true, updateError: null };
    case UPDATE_SUCCESS:
      return { ...state, updateInProgress: false };
    case UPDATE_ERROR:
      return { ...state, updateInProgress: false, updateError: payload };

      case VALIDATION_REQUEST:
        return { ...state, validationInProgress: true, validationError: null };
      case VALIDATION_SUCCESS:
        return { ...state, userReferralCode: payload,  validationInProgress: false };
      case VALIDATION_ERROR:
        return { ...state, validationInProgress: false, validationError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const addUsersShoppingCartRequest = option => ({
  type: ADD_SHOPPING_CART_REQUEST,
  payload: option,
});

export const addUsersShoppingCartSuccess = wasSave => ({
  type: ADD_SHOPPING_CART_SUCCESS,
  payload: wasSave,
});

export const deleteUsersShoppingCartRequest = option => ({
  type: DELETE_SHOPPING_CART_REQUEST,
  payload: option,
});

export const deleteUsersShoppingCartSuccess = wasSave => ({
  type: DELETE_SHOPPING_CART_SUCCESS,
  payload: wasSave,
});

export const transactionRequest = option => ({
  type: QUERY_TRANSACTION_REQUEST,
  payload: option,
});

export const queryTransactionsSuccess = transactions => ({
  type: QUERY_TRANSACTION_SUCCESS,
  payload: transactions,
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const updateRequest = () => ({ type: UPDATE_REQUEST })
export const updateSuccess = () => ({ type: UPDATE_SUCCESS })
export const updateError = e => ({ type: UPDATE_ERROR, error: true, payload: e})

export const validationRequest = () => ({ type: VALIDATION_REQUEST })
export const validationSuccess = userReferralCode => ({ type: VALIDATION_SUCCESS, payload: userReferralCode })
export const validationError = e => ({ type: VALIDATION_ERROR, error: true, payload: e})


/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId, isOffer, paymentMethod = null) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.


  const isManualPayment = orderParams.isManualPayment;
  const isTransition = !!transactionId;


  // const transition = isManualPayment ? TRANSITION_INITIATE_PAYMENT : isOffer ? (isTransition ? TRANSITION_ENQUIRY_SEND_OFFER : TRANSITION_SEND_OFFER) : (isTransition ? TRANSITION_ENQUIRY_REQUEST_PAYMENT : TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE);

  const transition = !isTransition ? 'transition/request-payment-quick-purchase' : 'transition/confirm-payment-quick-purchase'


  const isPrivilegedTransition = !isTransition;

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: orderParams,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params: orderParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    }, 'checkout');
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .then((response) => {
        const ListingQualityAssurance = orderParams.ListingQualityAssurance ? true : false
        if(paymentMethod === "paypal"){
          sendEditTransactionById({ id: response.id.uuid, metadata:{order_id: orderParams.order_id}});
          return response
        }else{
          sendEditTransactionById({ id: response.id.uuid, metadata:{shippingMethod: orderParams.shippingMethod, paymentMethod, ListingQualityAssurance: ListingQualityAssurance, referredCode: orderParams.referredCode, isFirstBuy: orderParams.isFirstBuy, phoneNumber: orderParams.phoneNumber}});
          return response;
        }
      })
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams, paymentMethod })
      .then(handleSucces)
      .then((response) => {

        const ListingQualityAssurance = orderParams.ListingQualityAssurance ? true : false

        if(paymentMethod === "paypal"){

          sendEditTransactionById({ id: response.id.uuid, metadata:{phoneNumber: orderParams.phoneNumber, shippingMethod: orderParams.shippingMethod,paymentMethod, ListingQualityAssurance: ListingQualityAssurance, referredCode: orderParams.referredCode, isFirstBuy: orderParams.isFirstBuy, phoneNumber: orderParams.phoneNumber}});
          // updateUser({id: })
          return response
        }else{
          sendEditTransactionById({ id: response.id.uuid, metadata:{phoneNumber: orderParams.phoneNumber, shippingMethod: orderParams.shippingMethod, paymentMethod, ListingQualityAssurance: ListingQualityAssurance, referredCode: orderParams.referredCode, isFirstBuy: orderParams.isFirstBuy, phoneNumber: orderParams.phoneNumber}});
          return response;
        }
      })
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = (orderParams, isOffer) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: isOffer ? TRANSITION_CONFIRM_PAYMENT : TRANSITION_CONFIRM_PAYMENT_QUICK_PURCHASE,
    params: isOffer ? {setupPaymentMethodForSaving: true} : {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      return updateTransactionMetadata({ id: order.id.uuid, metadata:{paymentIntentId: orderParams?.paymentIntent?.id}}).then(() => {
        dispatch(confirmPaymentSuccess(order.id));
        return order;
      });
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      }, 'checkout');
      throw e;
    });
};

export const queryUserTransactions = (user) => (dispatch, getState, sdk) => {
  dispatch(transactionRequest(true));


  const transitionsToFilterOut = [
    "transition/send-offer",
    "transition/accept-offer",
    "transition/offer-payment-successful",
    "transition/confirm-payment-quick-purchase",
    "transition/accept-quick-purchase",
    "transition/confirm-tracking",
    "transition/confirm-tracking-for-authentication",
    "transition/confirm-tracking-for-authentication-after-reminder",
    "transition/confirm-delivering-for-authentication",
    "transition/complete-authentication",
    "transition/send-reminder",
    "transition/confirm-tracking-after-reminder",
    "transition/confirm-delivering",
    "transition/confirm-delivering-by-operator",
    "transition/issue-a-claim",
    "transition/complete",
    "transition/complete-by-customer",
    "transition/finalize-transaction",
    "transition/claim",
    "transition/accept-claim",
    "transition/review-1-by-provider",
    "transition/review-2-by-provider",
    "transition/review-1-by-customer",
    "transition/review-2-by-customer",
    "transition/expire-review-period",
    "transition/expire-provider-review-period",
    "transition/expire-customer-review-period",
    "transition/request-payment-quick-purchase-after-enquiry",
    "transition/conekta-payment-quick-purchase",
    "transition/payment-validation",
    "transition/approve-payment",
    'transition/approve-offer-payment',
    "transition/initiate-manual-payment",
    "transition/complete-manual-payment",
    "transition/transition-to-manual-payment",
    "transition/request-clabe",
    "transition/validate-conekta",
    "transition/conekta-payout",
    "transition/conekta-payout-after-clabe",
    "transition/request-conekta-payout",
    "transition/conekta-payment-successful",
    "transition/failure-on-payout",
    "transition/retry-conekta-payment-successful",
    "transition/stripe-payout",
  ]

  return sdk.transactions
  .query({
    only: "order",
    lastTransitions: transitionsToFilterOut,
  }).then(apiRes => {
    dispatch(queryTransactionsSuccess(apiRes.transactions));
    dispatch(transactionRequest(false));
    return {firstBuy: apiRes.data.data.length === 0}
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(transactionRequest(false));
  });
};

export const addAbandonedShoppingCart = (buyerId, listingId) => (dispatch, getState, sdk) => {
  dispatch(addUsersShoppingCartRequest(true));
  return addUsersShoppingCart({buyerId, listingId}).then((response) => {
    dispatch(addUsersShoppingCartSuccess(response.wasSave));
    dispatch(addUsersShoppingCartRequest(false));
    return response;
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(addUsersShoppingCartRequest(false));
  });
};

export const deleteAbandonedShoppingCart = (buyerId, listingId) => (dispatch, getState, sdk) => {
  dispatch(deleteUsersShoppingCartRequest(true));
  return deleteUsersShoppingCart({buyerId, listingId}).then((response) => {
    dispatch(deleteUsersShoppingCartSuccess(response.wasSave));
    dispatch(deleteUsersShoppingCartRequest(false));
    return response;
  }).catch(e => {
    dispatch(showUserError(storableError(e)))
    dispatch(deleteUsersShoppingCartRequest(false));
  });
};


export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id || params.orderId;
  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId }, 'checkout');
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId, isOffer) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.

  const isTransition = !!transactionId;
  const transition = isOffer ? (isTransition ? TRANSITION_ENQUIRY_SEND_OFFER : TRANSITION_SEND_OFFER) : (isTransition ? TRANSITION_ENQUIRY_REQUEST_PAYMENT : TRANSITION_REQUEST_PAYMENT_QUICK_PURCHASE);
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params,
      }
    : {
        processAlias: config.bookingProcessAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    }, 'checkout');
    return dispatch(speculateTransactionError(storableError(e)));
  };


  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship

//with the new changes. newer users will only have the stripeCustomerId found within the user private data and not in a direct relationship with sharetribe.
// this is due to removing the dependency on sharetribe actions and have more control on the platform from what is charged to a user.
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    }); 
};

export const updateTransaction = orderParams => (dispatch, getState, sdk) => {
  return updateTransactionMetadata(orderParams).then(res => {
    return res
  })
}

export const updateSpeculateTransaction = orderParams => (dispatch, getState, sdk) => {
  const state = getState();
  const { speculatedTransaction } = state.CheckoutPage;

  dispatch(updateRequest())
  return recalculateLineItems(orderParams)
    .then(recalculateLineItems => {
      const lineItems = recalculateLineItems.lineItems.map(lineItem => formatLineItems(lineItem));
      const [payinTotal, payoutTotal] = recalculateLineItems.payTotals.map(payTotal => formatTotal(payTotal));
      const newTransition = { ...speculatedTransaction, attributes: { ...speculatedTransaction.attributes, lineItems, payinTotal, payoutTotal } };
      dispatch(initiateOrderSuccess(newTransition));
      dispatch(updateSuccess())

      dispatch(speculateTransactionSuccess(newTransition));
      return
  });
}

export const createShipmentQuotation = quotationParams => (dispatch, getState, sdk) => {

  return getShippingQuotation(quotationParams)
    .then(shippingRates => {
      return shippingRates
    })
    .catch(error => {
      console.log("error ", error);
    })

}

export const validatePaypalAmount = (orderId, transactionId) => (dispatch, getState, sdk) => {

  
}

export const sendEnquiry = (listingId, providerId, customerId, message)=> (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  let bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };

  return validateEnquiryTransaction({listingId, providerId, customerId})
    .then(apiResponse => {

      if (apiResponse.hasEnquiry) {
        //return something to do history.push to message
        return sdk.messages.send({ transactionId: apiResponse.txId.uuid, content: message }).then(() => {
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return apiResponse.txId;
        });
      } else {
        return sdk.transactions
        .initiate(bodyParams)
        .then(response => {
          const transactionId = response.data.data.id;

          // Send the message to the created transaction
          return sdk.messages.send({ transactionId, content: message }).then(() => {
            dispatch(sendEnquirySuccess());
            dispatch(fetchCurrentUserHasOrdersSuccess(true));
            return transactionId;
          });
        })
        .catch(e => {
          dispatch(sendEnquiryError(storableError(e)));
          throw e;
        });
      }
    }).catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    })
};


export const messageTransactionValidation = (listingId, providerId, customerId)=> (dispatch, getState, sdk) => {

  return validateEnquiryTransaction({listingId, providerId, customerId})
  .then(apiResponse => {
    //if apiResponse has a transaction that is an enquiry with this data then
    if (apiResponse.hasEnquiry) {
      return apiResponse.txId;
    } else {
      return null
    }
  }).catch(e => {
          dispatch(sendEnquiryError(storableError(e)));
      throw e;
  })
}

export const createPaypalOrder = (amount) => (dispatch, getState, sdk) => {
  return createPaypalOrderApi({amount}).then(res => {
    return res
  })
}

export const approvePaypalOrder = (paymentId, payerId) => (dispatch, getState, sdk) => {
  return approvePaypalPaymentApi({paymentId, payerId}).then(res =>{
    return res
  })
}

export const updateAddress = (id, address) => (dispatch, getState, sdk) => {
  
  dispatch(updateRequest())
  
  return updateUser({id, privateData: address}).then(res => {
    dispatch(updateSuccess())
    return res
  }).catch(e => {
    log.error(e, "update-checkout-address-error", {id, address}, 'checkout')
    dispatch(updateError())
  })
}

export const updatePhoneNumber = (id, phoneNumber) => (dispatch, getState, sdk) => {
  
  dispatch(updateRequest())
  const formatedPhoneNumber = phone => {
    if (phone.includes('+')) {
      return phone;
    }
    return '+521' + phone;
  };


  let receiverPhone = formatedPhoneNumber(phoneNumber);


  return updateUser({id, privateData: {phoneNumber: receiverPhone}}).then(res => {
    dispatch(updateSuccess())
    return res
  }).catch(e => {
    log.error(e, "update-phone-number-error", {id, phoneNumber}, 'checkout')
    dispatch(updateError())
  })
}

export const checkAndValidateReferralCode = (code, currentUserId) => (dispatch, getState, sdk) => {

  dispatch(validationRequest())

  return validateReferralCode({code, currentUserId}).then(res => {
    dispatch(validationSuccess(res))
    return res
  }).catch(e => {
    dispatch(validationError())
  })
}


export const updateUserPrivateData = (id, stripeCustomerId) => (dispatch, getState, sdk) => {
  return updateUser({id, privateData: {stripeCustomerId}}).then(res => {
    return
  }).catch(e => {
  })
}


