import unionWith from 'lodash/unionWith';
import config from '../../config';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { storableError } from '../../util/errors';

import * as log from '../../util/log';
import _ from 'lodash';
import { logEvent } from '../../util/logsEvent';

import { apiSearchListings } from '../../util/api';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_SUCCESS_APPEND = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS_APPEND';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_LIST_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_LIST_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  appendInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  likeInProgress: null,
  likeError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        appendInProgress: !!payload.searchParams.append,
        currentPageResultIds: payload.searchParams.append ? state.currentPageResultIds : [],
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS: {
      const results = resultIds(payload.data);
      const { keywords, track, coleccion, hotlist, featured, pub_category, collectionName } = state.searchParams;
      
      const quickLinks = config.quickLinks;
      
      let mainLabel = null;
      let optionLabel = null;
      if (pub_category) {
        for (const category of quickLinks) {
          const foundOption = category.options.find(option => option.key.split(',').includes(pub_category));
          if (foundOption) {
            mainLabel = category.label;
            optionLabel = foundOption.label;
            break;
          }
        }
      }
      
      const collectionNameString = `${collectionName ? collectionName : ""} ${coleccion ? coleccion : ""} ${hotlist ? "hotlist" : ""} ${featured ? "featured" : ""} ${mainLabel && optionLabel ? `${mainLabel}-${optionLabel}` : ""}`.replaceAll("_", " ").trim();
      if (collectionName || coleccion || hotlist || featured || pub_category) {
        logEvent('collection viewed', {
          ['collection name']: collectionNameString,
          ['number of search results']: results.length,
        });
      }
      
      if (track) {
        logEvent('collection viewed', {
          ['collection name']: track.replaceAll(" ", "-"),
          ['number of search results']: results.length,
        });
      }
      if (keywords?.length > 3) {
        logEvent('search completed', { keywords, ['number of search results']: results.length });
      }
      
      return {
        ...state,
        currentPageResultIds: results,
        pagination: payload.data.meta,
        searchInProgress: false,
        appendInProgress: false,
      };
    }
      
    case SEARCH_LISTINGS_SUCCESS_APPEND:
      return {
        ...state,
        currentPageResultIds: state.currentPageResultIds.concat(resultIds(payload.data)),
        pagination: payload.data.meta,
        searchInProgress: false,
        appendInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      return {
        ...state,
        searchInProgress: false,
        appendInProgress: false,
        searchListingsError: payload,
      };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      return { ...state, searchMapListingsError: payload };

    case SEARCH_LIST_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };
    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsSuccessAppend = response => ({
  type: SEARCH_LISTINGS_SUCCESS_APPEND,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1])],
        }
      : {};
  };

  const searchParamToArray = searchParams => {
    return searchParams ? searchParams.split(',') : [];
  };

  const {
    perPage,
    append,
    price,
    dates,
    pub_brand,
    pub_category,
    pub_size,
    pub_color,
    pub_use,
    pub_style,
    page,
    sort,
    featured,
    hotlist,
    coleccion,
    ...rest
  } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const queryString = searchParams.keywords ? searchParams.keywords : '';
  const lowerPrice = price ? priceMaybe.price[0] : undefined;
  const upperPrice = price ? priceMaybe.price[1] : undefined;

  let brand = searchParamToArray(pub_brand).map(brand =>
    `${decodeURIComponent(brand).replace(/'/g, "\\'").replace(/"/g, '\\"').replace('_', '&')}`
  );
  let category = searchParamToArray(pub_category);
  let color = searchParamToArray(pub_color);
  let size = searchParamToArray(pub_size);
  let style = searchParamToArray(pub_style);
  let use = searchParamToArray(pub_use);
  let pub_coleccion = searchParamToArray(coleccion);

  let filterArray = [];

  let featuredVar = featured !== undefined ? `featured${featured ? "=" : "!="}'true'` : null;
  let hotlistVar = hotlist !== undefined ? `hotlist${hotlist ? "=" : "!="}'true'` : null;

  pub_coleccion = pub_coleccion.map(col => `coleccion = '${col}'`);
  brand = brand.map(brand => `brand = '${brand}'`);
  category = category.map(category => `category ='${category}'`);
  color = color.map(color => `color ='${color}'`);
  size = size.map(size => `size = '${size}'`);
  style = style.map(style => `style = '${style}'`);
  use = use.map(use => `use = '${use}'`);

  if (lowerPrice !== undefined) filterArray.push(`(price > ${lowerPrice} AND price < ${upperPrice})`);
  if (brand.length > 0) filterArray.push(`(${brand.join(' OR ')})`);
  if (category.length > 0) filterArray.push(`(${category.join(' OR ')})`);
  if (color.length > 0) filterArray.push(`(${color.join(' OR ')})`);
  if (size.length > 0) filterArray.push(`(${size.join(' OR ')})`);
  if (style.length > 0) filterArray.push(`(${style.join(' OR ')})`);
  if (use.length > 0) filterArray.push(`(${use.join(' OR ')})`);
  if (pub_coleccion.length > 0) filterArray.push(`(${pub_coleccion.join(' OR ')})`);
  if (featuredVar && hotlistVar) {
    filterArray.push(`(${featuredVar} AND ${hotlistVar})`);
  } else if (featuredVar) {
    filterArray.push(`(${featuredVar})`);
  } else if (hotlistVar) {
    filterArray.push(`(${hotlistVar})`);
  }

  const filter = filterArray.join(' AND ');

  const sortObject = [];
  switch (sort) {
    case '-createdAt':
      sortObject.push('date_timestamp:asc');
      break;
    case 'createdAt':
      sortObject.push('date_timestamp:desc');
      break;
    case '-price':
      sortObject.push('price:asc');
      break;
    case 'price':
      sortObject.push('price:desc');
      break;
    case '-pub_updatedAt':
      sortObject.push('update_date_timestamp:desc');
      break;
    default:
      sortObject.push('date_timestamp:desc');
      break;
  }

  const meiliParams = {
    queryString,
    page,
    sort,
    filterArray,
  };

  return apiSearchListings(meiliParams)
    .then(async meiliResponse => {
      const returnedQueryIds = meiliResponse.hits.map(hit => hit.id);
      const meilisearchQueryIdsMaybe = { ids: returnedQueryIds || [] };

      const params = {
        ...rest,
        ...meilisearchQueryIdsMaybe,
        pub_sold: false,
        per_page: perPage,
        sort: sort,
      };

      delete params.keywords;
      delete params.sort;

      if (typeof navigator !== 'undefined') {
        try {
          const response = await sdk.listings.query(params);

          dispatch(addMarketplaceEntities(response));
          const meilisearchIdOrder = new Map(meilisearchQueryIdsMaybe.ids.map((t, i) => [t, i]));
          response.data.data = _.sortBy(response.data.data, r => meilisearchIdOrder.get(r.id.uuid));
          response.data.meta.totalItems = meiliResponse.estimatedTotalHits || 0;
          response.data.meta.totalPages = Math.ceil(meiliResponse.estimatedTotalHits / 50) || 0;
          response.data.meta.page = page;

          if (append) {
            dispatch(searchListingsSuccessAppend(response));
          } else {
            dispatch(searchListingsSuccess(response));
          }

          return response;
        } catch (e) {
          const data = {
            error_description: 'Search page error, error fetching listings through flex',
          };
          log.error(e, 'fetching-listings-failed', data, 'listing');
          dispatch(searchListingsError(storableError(e)));
          throw e;
        }
      }
    })
    .catch(e => {
      const data = {
        error_description: 'Search page error, error fetching listings through MeiliSearch',
      };
      log.error(e, 'fetching-listings-failed', data, 'listing');
      dispatch(searchListingsError(storableError(e)));
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_LIST_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};
