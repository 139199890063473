import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureListing, ensureTransaction } from '../../util/data';
import { dateFromAPIToLocalNoon } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import { txIsPaymentPendingQuickPurchase, txIsManualPaymentPending } from '../../util/transaction';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getAddressById, getShippingPriceByZipCode } from './../../ducks/Address.duck';
import {
  NamedRedirect,
  TransactionPanel,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  acceptSale,
  declineSale,
  loadData,
  setInitialValues,
  sendMessage,
  messageTransactionValidation,
  sendEnquiry,
  updateClabe,
  sendReview,
  fetchMoreMessages,
  fetchTransactionLineItems,
  closeError,
  setApiReceived,
  setApiConfirmed,
} from './TransactionPage.duck';
import { getParamsListing, logEvent } from '../../util/logsEvent';
import css from './TransactionPage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TransactionPageComponent = props => {
  const {
    currentUser,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    fetchMessagesError,
    fetchMessagesInProgress,
    totalMessagePages,
    oldestMessagePageFetched,
    fetchTransactionError,
    history,
    intl,
    messages,
    onManageDisableScrolling,
    onSendMessage,
    onSendReview,
    onShowMoreMessages,
    params,
    scrollingDisabled,
    sendMessageError,
    sendMessageInProgress,
    sendReviewError,
    sendReviewInProgress,
    transaction,
    transactionRole,
    acceptInProgress,
    acceptSaleError,
    declineInProgress,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    hasAddressError,
    addressMessage,
    isSearching,
    isClientError,
    onCloseAlert,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateClabeError,
    updateClabeInProgress,
    isMessage
  } = props;


  const [isEnquiryModalOpen, setIsEnquiryModalOpen] = React.useState(false);
  const [isClabeModalOpen, setIsClabeModalOpen] = React.useState(false);


  const currentTransaction = ensureTransaction(transaction);
  const currentListing = ensureListing(currentTransaction.listing);
  const isProviderRole = transactionRole === PROVIDER;
  const isCustomerRole = transactionRole === CUSTOMER;

  

  const redirectToCheckoutPageWithInitialValues = (initialValues, listing) => {
    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);


    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: currentListing.id.uuid, slug: createSlug(currentListing.attributes.title) },
        {}
      )
    );
  };

  const onContactUser = () =>{
    const { currentUser, history, callSetInitialValues, params, location, onMessageTransactionValidation } = props;
    const routes = routeConfiguration();

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });
      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {

      const receiver = currentListing.author.id.uuid;
      const sender = currentUser.id.uuid;

      onMessageTransactionValidation(currentListing.id.uuid, receiver, sender)
        .then(txId => {
          if(txId !== null){
            history.push(
              createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
            );
          }else{
            setIsEnquiryModalOpen(true)
          }
        }).catch(() => {
          // Ignore, error handling in duck file
        });
    }
  }


  const onSubmitEnquiry = (values) => {
    const { history, params, onSendEnquiry, currentUser, transaction } = props;
    const routes = routeConfiguration();
    const listingId = transaction.listing.id.uuid;
    const { message } = values;

    //send listing so that onEnquiry we can validate that there is a message with this listingId
    // let enquiryParamas = {listing}
    const receiver = transaction.provider.id.uuid
    const sender = currentUser.id.uuid;


    onSendEnquiry(listingId,receiver,sender, message.trim())
      .then(txId => {
        setIsEnquiryModalOpen(false)
        // Redirect to MessageCustomerDetailsPage
        history.push(
          createResourceLocatorString('MessageCustomerDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  const onSubmitClabe = (values) => {
    const { onSubmitClabe } = props;
    const { clabe } = values;

    //send listing so that onEnquiry we can validate that there is a message with this listingId
    // let enquiryParamas = {listing}

    onSubmitClabe(currentUser.id.uuid, currentTransaction.id.uuid, clabe)
      .then(txId => {
        setIsClabeModalOpen(false)
        window.location.reload(false);
      })
      .catch(e => {
        // console.log(e)
        // Ignore, error handling in duck file
      });
  }

  // If payment is pending, redirect to CheckoutPage
  if (
    (txIsPaymentPendingQuickPurchase(currentTransaction) || txIsManualPaymentPending(currentTransaction)) &&
    isCustomerRole &&
    currentTransaction.attributes.lineItems
  ) {
    const currentBooking = ensureListing(currentTransaction.booking);

    const initialValues = {
      listing: currentListing,
      // Transaction with payment pending should be passed to CheckoutPage
      transaction: currentTransaction,
      // Original bookingData content is not available,
      // but it is already used since booking is created.
      // (E.g. quantity is used when booking is created.)
      bookingData: {},
      bookingDates: {
        bookingStart: dateFromAPIToLocalNoon(currentBooking.attributes.start),
        bookingEnd: dateFromAPIToLocalNoon(currentBooking.attributes.end),
      },
    };

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);
  }

  // Customer can create a booking, if the tx is in "enquiry" state.
  const handleSubmitBookingRequest = values => {
    const { price, ...bookingData } = values;
    const tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 360);
    const today = new Date();
    today.setDate(new Date().getDate() + 1);

    const initialValues = {
      listing:currentListing,
      bookingData,
      bookingDates: {
        bookingStart: today,
        bookingEnd: tomorrow,
        customPrice: price ? price : 1,
        shippingPrice: 109
      },
      //null to create new transaction, use currentTransaction if you need to use same transaction
      transaction:null,
      confirmPaymentError: null,
    };
    if(price){
    logEvent('offer submitted',
      {...getParamsListing(currentListing), ['offer in percentage']: parseInt((1 - price)*100) ,['offer amount']: parseInt((currentListing.attributes.price.amount/100) * price) })
    }else{
      logEvent('purchase submitted', getParamsListing(currentListing))
    }

    redirectToCheckoutPageWithInitialValues(initialValues, currentListing);


  };

  const deletedListingTitle = intl.formatMessage({
    id: 'TransactionPage.deletedListing',
  });
  const listingTitle = currentListing.attributes.deleted
    ? deletedListingTitle
    : currentListing.attributes.title;

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.customer &&
    currentTransaction.provider &&
    !fetchTransactionError;

  const isOwnSale =
    isDataAvailable &&
    isProviderRole &&
    currentUser.id.uuid === currentTransaction.provider.id.uuid;
  const isOwnOrder =
    isDataAvailable &&
    isCustomerRole &&
    currentUser.id.uuid === currentTransaction.customer.id.uuid;

  if (isDataAvailable && isProviderRole && !isOwnSale) {
    // eslint-disable-next-line no-console
    return <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />;
  } else if (isDataAvailable && isCustomerRole && !isOwnOrder) {
    // eslint-disable-next-line no-console
    return <NamedRedirect name="InboxPage" params={{ tab: 'orders' }} />;
  }

  const detailsClassName = classNames(css.tabContent, css.tabContentVisible);

  const fetchErrorMessage = isCustomerRole
    ? 'TransactionPage.fetchOrderFailed'
    : 'TransactionPage.fetchSaleFailed';
  const loadingMessage = isCustomerRole
    ? 'TransactionPage.loadingOrderData'
    : 'TransactionPage.loadingSaleData';

  const loadingOrFailedFetching = fetchTransactionError ? (
    <p className={css.error}>
      <FormattedMessage id={`${fetchErrorMessage}`} />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id={`${loadingMessage}`} />
    </p>
  );

  const initialMessageFailed = !!(
    initialMessageFailedToTransaction &&
    currentTransaction.id &&
    initialMessageFailedToTransaction.uuid === currentTransaction.id.uuid
  );

  const confirmBtnAction = () => {
    const { onSetApiReceived } = props;
    onSetApiReceived(currentTransaction.id.uuid);
  }

  const confirmReceivedBtnAction = () => {
    const { onSetApiConfirmed } = props;
    onSetApiConfirmed(currentTransaction.id.uuid, currentListing.id.uuid);
  }

  // TransactionPanel is presentational component
  // that currently handles showing everything inside layout's main view area.
  const panel = isDataAvailable ? (
    <TransactionPanel
      onContactUser={onContactUser}
      className={detailsClassName}
      currentUser={currentUser}
      transaction={currentTransaction}
      fetchMessagesInProgress={fetchMessagesInProgress}
      totalMessagePages={totalMessagePages}
      oldestMessagePageFetched={oldestMessagePageFetched}
      messages={messages}
      initialMessageFailed={initialMessageFailed}
      savePaymentMethodFailed={savePaymentMethodFailed}
      fetchMessagesError={fetchMessagesError}
      sendMessageInProgress={sendMessageInProgress}
      sendMessageError={sendMessageError}
      sendReviewInProgress={sendReviewInProgress}
      sendReviewError={sendReviewError}
      onManageDisableScrolling={onManageDisableScrolling}
      onShowMoreMessages={onShowMoreMessages}
      onSendMessage={onSendMessage}
      onSendReview={onSendReview}
      transactionRole={transactionRole}
      onAcceptSale={onAcceptSale}
      onDeclineSale={onDeclineSale}
      acceptInProgress={acceptInProgress}
      declineInProgress={declineInProgress}
      acceptSaleError={acceptSaleError}
      declineSaleError={declineSaleError}
      nextTransitions={processTransitions}
      onSubmitBookingRequest={handleSubmitBookingRequest}
      timeSlots={timeSlots}
      fetchTimeSlotsError={fetchTimeSlotsError}
      onFetchTransactionLineItems={onFetchTransactionLineItems}
      lineItems={lineItems}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      fetchLineItemsError={fetchLineItemsError}
      isSearching={isSearching}
      hasAddressError={hasAddressError}
      isClientError={isClientError}
      addressMessage={addressMessage}
      onCloseAlert={onCloseAlert}
      currentListing={currentListing}
      confirmBtnAction={confirmBtnAction}
      confirmReceivedBtnAction={confirmReceivedBtnAction}
      isEnquiryModalOpen={isEnquiryModalOpen}
      onCloseEnquiryModal={() => setIsEnquiryModalOpen(false)}
      onSubmitEnquiry={onSubmitEnquiry}
      sendEnquiryError={sendEnquiryError}
      sendEnquiryInProgress={sendEnquiryInProgress}


      isClabeModalOpen={isClabeModalOpen}
      onCloseClabeModal={() => setIsClabeModalOpen(false)}
      onOpenClabeModal={() => setIsClabeModalOpen(true)}

      onSubmitClabe={onSubmitClabe}
      updateClabeError={updateClabeError}
      updateClabeInProgress={updateClabeInProgress}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: isMessage ? 'MessagePage.title' : 'TransactionPage.title' }, { title: listingTitle })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.root}>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

TransactionPageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
  hasAddressError: false,
  addressMessage: null,
  isSearching: false,
  isClientError: false,
};

const { bool, func, oneOf, shape, string, array, arrayOf, number } = PropTypes;

TransactionPageComponent.propTypes = {
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  fetchTransactionError: propTypes.error,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  fetchMessagesError: propTypes.error,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailedToTransaction: propTypes.uuid,
  savePaymentMethodFailed: bool,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,

  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  updateClabeError: propTypes.error,

  onMessageTransactionValidation: func.isRequired,
  


  // line items
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  hasAddressError: bool,
  addressMessage: string,
  isSearching: bool,
  isClientError: bool,

  onCloseAlert: func,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    transactionRef,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    hasAddressError,
    addressMessage,
    isSearching,
    isClientError,
    onSetApiConfirmed,
    onSetApiReceived,
    onAcceptSale,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateClabeError,
    updateClabeInProgress
  } = state.TransactionPage;
  const { currentUser } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    currentUser,
    fetchTransactionError,
    acceptSaleError,
    declineSaleError,
    acceptInProgress,
    declineInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    savePaymentMethodFailed,
    sendMessageInProgress,
    sendMessageError,
    sendReviewInProgress,
    sendReviewError,
    timeSlots,
    fetchTimeSlotsError,
    processTransitions,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    hasAddressError,
    addressMessage,
    isSearching,
    isClientError,
    onSetApiConfirmed,
    onSetApiReceived,
    onAcceptSale,
    sendEnquiryInProgress,
    sendEnquiryError,
    updateClabeError,
    updateClabeInProgress
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAcceptSale: (transactionId, provider, client, listingTitle,  role) => {
      const providerId = provider.id.uuid;
      const clientId = client.id.uuid;
      dispatch(acceptSale(transactionId, providerId, clientId,listingTitle, role))
    },
    onSetApiReceived: (transactionId) => dispatch(setApiReceived(transactionId)),
    onSetApiConfirmed: (transactionId, listingId) => dispatch(setApiConfirmed(transactionId, listingId)),
    onDeclineSale: transactionId => dispatch(declineSale(transactionId)),
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    onSendMessage: (txId, message) => dispatch(sendMessage(txId, message)),
    onSendEnquiry: (listingId, providerId, customerId, message) => dispatch(sendEnquiry(listingId, providerId, customerId, message)),
    onSubmitClabe: (userId, transactionId, clabe) => dispatch(updateClabe(userId, transactionId, clabe)),
    onMessageTransactionValidation: (listingId, providerId, customerId) => dispatch(messageTransactionValidation(listingId, providerId, customerId)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onSendReview: (role, tx, reviewRating, reviewContent, listingId = null) =>
      dispatch(sendReview(role, tx, reviewRating, reviewContent, listingId)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
      dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
    onCloseAlert: () => {
      dispatch(closeError())
    }
  };
};

const TransactionPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TransactionPageComponent);

TransactionPage.loadData = loadData;
TransactionPage.setInitialValues = setInitialValues;

export default TransactionPage;
